document.addEventListener('DOMContentLoaded', function (event) {
  if (typeof botId === 'undefined' || typeof cb_host === 'undefined') {
    return
  }
  // border-radius: 5px 5px 0 0;
  var styles = `
  [id^=chatbot_] {
    display: block;
    border-radius: 50%;
    background-color: transparent;
    pointer-events: none;
    z-index: 2147483639;
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 120px;
    height: 120px;
    overflow: hidden;
    opacity: 1;
    max-width: 100%;
    max-height: 100%;
    transition: .2s ease-out;
  }
  [id^=chatFrame_] {
    pointer-events: all;
    background: none;
    border: 0px;
    float: none;
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    min-height: 0px;
  }
  @media (max-width: 492px) {
    [id^=chatbot_] {
      right: 0;
      bottom: 0;
      width: 70px;
      height: 70px;
    }
    [class^=img-max] {
      max-width: 70px !important;
      min-width: 70px !important;
      height: 70px !important;
      }
    }
  }
  `
  // add css
  var styleSheet = document.createElement('style')
  styleSheet.type = 'text/css'
  styleSheet.innerText = styles
  styleSheet.appendChild(document.createTextNode(unescape(styles)))
  document.getElementsByTagName('head')[0].appendChild(styleSheet)
  // check if iframe exists
  var iFrame = document.getElementById('chatFrame_' + botId)
  if (iFrame) {
    iFrame.remove()
  }
  // iframe's parent
  var divElement = document.createElement('div')
  divElement.id = 'chatbot_' + botId
  // iframe
  var frame = document.createElement('iframe')
  frame.id = 'chatFrame_' + botId
  frame.src = cb_host + botId
  divElement.appendChild(frame)
  document.body.append(divElement)
  var isMinimized = true
  frame.onload = () => {
    window.addEventListener(
      'message',
      function (e) {
        // message that was passed from iframe page
        var { action, data } = e
        switch (data.action) {
          case 'ChatOpen':
            isMinimized = false
            divElement.style.height = data.height + 'px'
            divElement.style.borderRadius = data.borderRadius
            if (window.innerWidth > 492) {
              divElement.style.width = data.width + 'px'
            } else {
              divElement.style.width = '90vw'
            }
            break
          case 'LoadedData':
            divElement.style.height = '500px'
            divElement.style.width = '350px'
            divElement.style.borderRadius = '5px 5px 0 0'
            break
          case 'ChatMinimized':
            isMinimized = true
            divElement.style.height = null
            divElement.style.width = null
            divElement.style.borderRadius = null
        }
      },
      false
    )
  }
  window.addEventListener('resize', function () {
    var windowWidth = window.innerWidth
    if (windowWidth > 492) {
      if (divElement.style.width !== '350px' && !isMinimized) {
        divElement.style.width = '350px'
      }
    } else {
      if (divElement.style.width === '350px' && !isMinimized) {
        divElement.style.width = '90vw'
      }
    }
  })
})
